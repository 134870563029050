<template>
  <div>
    <div class="container row mx-auto justify-content-center" style="margin-top: 100px;">
      <div v-if="!items || items.length === 0" class="text-center mt-4">
        <p>Belum Ada Pengaduan</p>
      </div>
      <div v-else class="d-flex flex-wrap justify-content-center">
        <div v-for="item in items" class="card col-md-3 mx-3 mb-4 p-0" style="border-radius: 16px; overflow: hidden; box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);" :key="item.id">
          <img class="card-img-top" :src="item.paket_pekerjaan_befores[0].image" alt="Card image cap" style="object-fit: cover; height: 200px; width: 100%;">
          <div class="card-body text-center">
            <h5 class="card-title">{{ item.kecamatan }}</h5>
            <p class="card-text">{{ item.nama_paket }}</p>
            <button @click="edit(item)" class="btn btn-primary">Detail</button>
          </div>
        </div>
      </div>
    </div>

  <CModal 
      size="lg"
      :title="isUpdate ? 'Pengaduan' : 'Pengaduan'"
      centered
      :color="isUpdate ? 'success' : 'primary'"
      :show.sync="createModal"
    >
      <div class="row">
        <div class="col">
          <CInput
            v-model="form.sumber_dana"
            label="Nama Pengadu"
            placeholder="ketik disini"
            disabled
          />
          <CInput
            v-model="form.pemenang_tender"
            label="No. Telepon"
            placeholder="ketik disini"
            disabled
          />
          <CInput
            v-model="form.alamat_pekerjaan"
            label="Alamat Pengadu"
            placeholder="ketik disini"
            disabled
          />
        </div>
        <div class="col">
          <label for="kecamatan-select">Kecamatan</label>
          <v-select
            disabled
            v-model="form.kecamatan"
            :options="kecamatans"
            label="name"
            placeholder="Pilih"
            class="form-control"
            id="kecamatan-select"
          >
          </v-select>
          <br />
          <CTextarea
            disabled
            v-model="form.nama_paket"
            label="Isi Laporan"
            placeholder="ketik disini"
          />
          <!-- <select
            name=""
            v-model="form.kecamatan"
            class="form-control"
            placeholder="Pilih"
            id=""
          >
            <option
              v-for="kecamatan in kecamatans"
              :value="kecamatan.name"
              :key="kecamatan.name"
            >
              {{ kecamatan.name }}
            </option>
          </select> -->
          <br />
          <!-- <select
            name=""
            v-model="form.status_pekerjaan"
            class="form-control"
            placeholder="Pilih"
            id=""
          >
            <option
              v-for="dd in desa"
              :value="dd"
              :key="dd + (Math.random())"
            >
              {{ dd }}
            </option>
          </select> -->
          <br />
        </div>
      </div>

      <!-- <input type="text" id="search" placeholder="Search for a place" class="form-control mb-3" v-model="searchQuery" @input="searchPlace" /> -->
      <!-- <div id="map" style="height: 300px;"></div> -->

      <div class="row mt-3">
        <div class="col">
          <CInput
            disabled
            v-model="form.longitude"
            label="Longitude"
            placeholder="ketik disini"
          />
        </div>
        <div class="col">
          <CInput
            disabled
            v-model="form.latitude"
            label="Latitude"
            placeholder="ketik disini"
          />
        </div>
      </div>

      <!-- bagian upload -->
      

      <p>Dokumen 1</p>
      <div class="row">
        <div
          class="col-md-3"
          v-for="(item, index) in imageListBefore"
          :key="item"
        >
          <CCardLink :href="item" target="_blank" class="">
            <CCardImg
              :src="item"
              style="object-fit: cover"
              height="auto"
              variant="full"
            />
          </CCardLink>

          <!-- <button
            class="btn btn-danger btn-sm btn-block mt-2"
            @click="deleteImageBefore('update', index)"
          >
            Hapus
          </button> -->
          <br />
        </div>
      </div>
      <!-- bagian upload -->
      

      <!-- bagian upload -->
      
      
      <br />
      <p>Dokumen 2</p>
      <div class="row">
        <div
          class="col-md-3"
          v-for="(item, index) in imageListProcess"
          :key="item"
        >
          <CCardLink :href="item" target="_blank" class="">
            <CCardImg
              :src="item"
              style="object-fit: cover"
              height="auto"
              variant="full"
            />
          </CCardLink>

          <button
            class="btn btn-danger btn-sm btn-block mt-2"
            @click="deleteImageProcess('update', index)"
          >
            Hapus
          </button>
          <br />
        </div>
      </div>
      <!-- bagian upload -->

      <!-- bagian upload -->
      <!-- <div class="row">
        <div class="col text-center">
          <label for="">Upload Foto Dokumen 3 : </label>
          <div
            v-if="imageListAfter.length <= 3"
            class="card border d-flex justify-content-center"
            style="height: 250px"
          >
            <div class="align-self-center mb-3">
              (MAKS 4)
              <br />
              <img src="@/assets/upload.png" width="100px" alt="" />
            </div>
            <button
              class="btn btn-sm btn-primary align-self-center"
              style="width: 15%"
              @click="uploadAfter"
            >
              Upload Disini
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="div">
            <input
              hidden
              type="file"
              class="form-control"
              ref="uploadFieldAfter"
              @change="selectFileAfter"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="col-md-3"
          v-for="(item, index) in imageListAfter"
          :key="item"
        >
          <CCardLink :href="item" target="_blank" class="">
            <CCardImg
              :src="item"
              style="object-fit: cover"
              height="auto"
              variant="full"
            />
          </CCardLink>

          <button
            class="btn btn-danger btn-sm btn-block mt-2"
            @click="deleteImageAfter('update', index)"
          >
            Hapus
          </button>
          <br />
        </div>
      </div> -->
      <!-- bagian upload -->

      <template slot="footer">
        <div>
          <!-- <button @click="cancel" class="btn btn-secondary mr-3">Batal</button> -->

          <!-- <button @click="submit" v-if="!isUpdate" class="btn btn-primary">
            Tambah Pengaduan
          </button> -->
          <!-- <button @click="update" v-if="isUpdate" class="btn btn-primary">
            Update Pengaduan
          </button> -->
        </div>
      </template>
    </CModal>
  </div>
</template>

<script>
import * as data from "@/model/paket-pekerjaan-public"
import formatNumber from '@/directives/formatNumber';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

import markerIcon from '@/assets/marker.png';

// Atur icon default untuk marker
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon,
  iconUrl: markerIcon,
});

export default {
  directives: {
    formatNumber
  },
  data() {
    return {
      fields: [],
      items: [],
      total: 0,
      params: {
        sorttype: "desc",
        sortby: "id",
        row: 50,
        page: 1,
        type: [0, 1],
        keyword: "",
        year: "2024",
        kecamatan: "",
        jenis_pemilihan: "",
        jenis_lahan: "",
        jenis_pekerjaan: "Pengaduan"
      },
      form: {},
      isUpdate: false,
      createModal: false,
      imageListBefore: [],
      imageListProcess: [],
      category: [],
      kecamatans: [],
desa: [
'Alamendah',
'Andir',
'Arjasari',
'Babakan',
'Babakanpeuteuy',
'Bandasari.',
'Banjaran.Kulon',
'Banjaran.Wetan',
'Banjarsari',
'Banyusari',
'Baros',
'Batukarut',
'Biru',
'Bojong',
'Bojongemas',
'Bojongkunci',
'Bojongloa',
'Bojongmalaka',
'Bojongmanggu',
'Bojongsalam',
'Bojongsari.',
'Bojongsoang',
'Buahbatu',
'Bumiwangi',
'Buninagara',
'Campakamulya',
'Cangkuang',
'Cangkuang.Kulon',
'Cangkuang.Wetan',
'Ciapus',
'Ciaro',
'Cibeet',
'Cibeunying',
'Cibeureum.',
'Cibiru.Hilir',
'Cibiru.Wetan',
'Cibodas',
'Ciburial',
'Cicalengka.Kulon',
'Cicalengka.Wetan',
'Cigentur',
'Cigondewah.Hilir',
'Cihanyir',
'Cihawuk',
'Ciherang',
'Ciheulang.',
'Cijagra',
'Cikadut',
'Cikalong',
'Cikancung',
'Cikasungka',
'Cikawao',
'Cikembang.',
'Cikitu',
'Cikoneng.',
'Cikuya',
'Cilame',
'Cilampeni',
'Cilengkrang',
'Cileunyi.Kulon',
'Cileunyi.Wetan',
'Ciluluk',
'Ciluncat',
'Cimaung.',
'Cimekar',
'Cimenyan',
'Cinanggela',
'Cingcin',
'Cinunuk',
'Cipagalo',
'Cipaku',
'Cipanjalu',
'Ciparay',
'Cipedes',
'Cipelah',
'Cipeujeuh',
'Cipinang.',
'Ciporeat',
'Cisondari',
'Citaman',
'Citeureup',
'Ciwidey',
'Cukanggenteng',
'Dampit',
'Dayeuhkolot',
'Drawati',
'Dukuh',
'Gajahmekar',
'Gandasari',
'Ganjar.Sabar',
'Girimekar',
'Girimulya',
'Gunung.Leutik.',
'Haurpugur',
'Hegarmanah',
'Ibun.',
'Indragiri',
'Jagabaya',
'Jatiendah.',
'Jatisari',
'Jelegong',
'Kamasan.',
'Karamatmulya',
'Karangtunggal',
'Karyalaksana',
'Katapang',
'Kel.Baleendah.',
'Kel.Manggahang',
'Kel.Pasawahan',
'Kel.Rancaekek.Kencana',
'Kel.Sulaeman',
'Kendan',
'Kiangroke.',
'Kopo',
'Kutawaringin',
'Lagadar',
'Laksana',
'Lamajang',
'Lampegan.',
'Langensari.',
'Langonsari',
'Lebakwangi',
'Lengkong',
'Leubakmuncang',
'Linggar',
'Loa',
'Majakerta',
'Majalaya',
'Majasetra',
'Malakasari',
'Malasari.',
'Mandalahaji',
'Mandalamekar',
'Mandalasari',
'Mandalawangi',
'Mangunharja.',
'Mangunjaya',
'Margaasih',
'Margahayu.Selatan',
'Margahayu.Tengah',
'Margahurip',
'Margaluyu',
'Margamekar',
'Margamukti',
'Margamulya',
'Maruyung',
'Mekarjaya',
'Mekarlaksana',
'Mekarmaju',
'Mekarmanik.',
'Mekarpawitan',
'Mekarrahayu',
'Mekarsaluyu',
'Mekarsari.',
'Mekarwangi',
'Melatiwangi',
'Nagrak',
'Nagreg',
'Nanjung',
'Nanjungmekar',
'Narawita',
'Neglasari',
'Neglawangi',
'Nengkelan',
'Padamukti',
'Padamulya.',
'Padasuka',
'Padaulun',
'Pakutandang',
'Pamekaran',
'Pameuntasan',
'Pananjung',
'Panenjoan',
'Pangalengan.',
'Pangauban',
'Pangguh.',
'Panundaan',
'Panyadap',
'Panyirapan',
'Panyocokan',
'Parungserab',
'Pasirhuni',
'Pasirjambu',
'Pasirmulya',
'Patengan',
'Patrolsari',
'Pinggirsari',
'Pulosari',
'Rahayu',
'Rancaekek.Kulon',
'Rancaekek.Wetan',
'Rancakasumba',
'Rancakole',
'Rancamanyar',
'Rancamulya',
'Rancatungku',
'Rawabogo',
'Resmi.Tinggal.',
'Sadu',
'Sagaracipta',
'Sangiang',
'Sangkanhurip',
'Santosa',
'Sarimahi',
'Sayati',
'Sekarwangi',
'Serangmekar',
'Sindanglaya',
'Sindangpanon',
'Sindangsari',
'Solokanjeruk',
'Soreang',
'Srirahayu',
'Sudi',
'Sugihmukti',
'Sukajadi',
'Sukaluyu',
'Sukamaju',
'Sukamanah',
'Sukamantri',
'Sukamenak',
'Sukamukti',
'Sukamulya',
'Sukanagara',
'Sukapura',
'Sukarame.',
'Sukaresmi',
'Sukasari',
'Sukaweuning',
'Sumbersari.',
'Talun',
'Tanggulun.',
'Tangsimekar',
'Tanjunglaya',
'Tanjungsari',
'Tanjungwangi',
'Tarajusari.',
'Tarumajaya',
'Tegal.Sumedang',
'Tegalluar',
'Tenjolaya',
'Tribaktimulya',
'Waluya',
'Wanasuka',
'Wangisagara',
'Wargaluyu',
'Wargamekar',
'Warjabakti',
'Warnasari.'
      ].map(desa => ({ name: desa }))
    }
  },
  computed: {
    computedItems() {
      return this.items.map((item, index) => {
        return {
          index: index + 1 + ".",
          ...item,
          nilai_kontrak: item.nilai_kontrak
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          full_name: item.user != null ? item.user.full_name : "",
          opd: item.role != null ? item.role.name : "",
          jenis_pemilihan: item.jenis_pemilihan != null ? item.jenis_pemilihan : "",
          pemenang_tender: item.pemenang_tender != null ? item.pemenang_tender : "",
          created_at: item.created_at.slice(0, 10),
        }
      })
    }
  },
  methods: {
    edit(item) {
      this.form = item
      this.isUpdate = true
      this.createModal = true
      this.imageListBefore = this.extractGallery(item.paket_pekerjaan_befores)
    },
    extractGallery(item) {
      let data = []
      item.forEach((element) => {
        data.push(element.image)
      })
      return data
    },
    getKecamatan() {
      this.$store
        .dispatch("sbu/getKecamatan", this.params)
        .then((resp) => {
          this.kecamatans = resp.data
          console.log(this.kecamatans, "kecamatan")
        })
        .catch((e) => {
          this.$toast.error("gagal mengambil data  \n", e)
        })
    },
    getCategory() {
      this.$store
        .dispatch("category/getCategory", this.params)
        .then((resp) => {
          this.category = resp.data.data
          // console.log(this.category, "category")
        })
        .catch((e) => {
          this.$toast.error("gagal mengambil data  \n", e)
        })
    },
    getData() {
      var loading = this.$loading.show()
      this.$store
        .dispatch("paket_pekerjaan_public/getPaketPekerjaan", this.params)
        .then((resp) => {
          this.items = resp.data.data
          this.total = resp.data.total

          // khusus untuk checkbox
          // this.selectedItems = []
          // this.items.forEach((element) => {
          //   if (this.isSelectedAll) {
          //     element.select = true
          //     this.selectedItems.push(element.id)
          //   } else {
          //     element.select = false
          //   }
          // })
          loading.hide()
        })
        .catch((e) => {
          this.$toast.error("gagal mengambil data  \n", e)
          loading.hide()
        })
    },
    onModalShow() {
      console.log('adsjksajd')
      this.map.invalidateSize()
    },
    initMap() {
      // Inisialisasi peta
      this.form.longitude = "107.621532"
      this.form.latitude = "-7.13407"
      
      this.map = L.map('map').setView([-7.13407, 107.621532], 13);

      // Tambahkan tile layer dari OpenStreetMap
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(this.map);

      // Tambahkan marker yang bisa digeser
      this.marker = L.marker([-7.13407, 107.621532], {
        draggable: true
      }).addTo(this.map);

      // Event listener untuk mendapatkan koordinat saat marker digeser
      this.marker.on('dragend', (event) => {
        const position = event.target.getLatLng();
        this.form.longitude = position.lng;
        this.form.latitude = position.lat;
      });
    },
  },
  mounted() {
    // this.initMap();

    this.getData()
    this.getCategory()
    this.getKecamatan()

    // const modal = document.getElementById('mapModal');
    // modal.addEventListener('shown.bs.modal', () => {
    //   this.map.invalidateSize();
    // });
  },
  created() {
    this.fields = data.fields
  },
}
</script>
